import React, { useState } from 'react';
import { Button, Container, Row, Col, Modal } from 'react-bootstrap';
import Footer from './Footer.js';
import FAQ from './FAQ.js';
import { Link } from 'react-router-dom';
import '../../custom.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ContactUsForm from './ContactUsForm.js';

const Home = () => {
    // State for handling modal visibility
    const [showModal, setShowModal] = useState(false);

    // Functions to open and close the modal
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <div className="home-page">
            {/* Banner section using Container, Row, and Col */}
            <Container fluid>
                <Row className="align-items-center justify-content-center text-left">
                    <Col xs={12} md={6} className="content-style">
                        {/* 
                        <div style={{ position: 'relative', marginTop: '-20px', marginLeft: '5px' }}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 220 80"
                                width="220"
                                height="80"
                                style={{
                                    position: 'absolute',
                                    top: '-50px',
                                    left: '-10px',
                                    zIndex: '-1',
                                }}
                            >
                                <path
                                    d="M70,50 C100,20 180,10 200,5"
                                    stroke="#f5413a"
                                    strokeWidth="2"
                                    fill="none"
                                    strokeLinecap="round"
                                />
                                <polygon
                                    points="200,5 195,0 198,5 195,10"
                                    fill="#f5413a"
                                />
                            </svg>
                            <span
                                style={{
                                    fontFamily: "'Patrick Hand', cursive",
                                    fontSize: '16px',
                                    color: '#4B88A2',
                                    position: 'absolute',
                                    top: '-70px',
                                    left: '40px',
                                }}
                            >
                                Real-Time Adaptive Modelling and Analysis
                            </span>
                        </div>
                        */}
                        <h1>ARAMA -&nbsp;
                            <span style={{ color: '#f5413a' }}>Your 24/7 Business Advisor</span>
                        </h1>
                        <p className="sub-text" style={{ color: '#252627' }}>
                            Empower your business with affordable, AI-driven insights—all backed by exceptional customer support.
                        </p>


                        <Button variant="primary"
                            className="custom-button"
                            onClick={handleOpenModal}
                            style={{
                                marginTop: '20px',
                                backgroundColor: '#4B88A2',
                                padding: '12px 24px', // Larger padding
                                fontSize: '18px', // Larger font size
                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
                            }}>
                            <i className="bi bi-rocket-takeoff" style={{ marginRight: '8px' }}></i>
                            Get Started Now! (100% Secure & Private)
                        </Button>
                    </Col>
                    <Col xs={12} md={6} className="home-banner content-style">
                        <img src="/images/home/neural_networks.jpg" alt="Banner" className="img-fluid trapezoid-img-left" />
                    </Col>
                </Row>
            </Container>

            <Container fluid className="my-5">
                <Row className="align-items-center justify-content-center text-center">
                    <Col xs={12} sm={7} className="content-style bordered-title">
                        <h2>Transform Data into Decisions.</h2>
                        <p style={{ fontSize: '16px', color: '#252627' }}>
                            Unlock your data's full potential for growth. Our AI-driven platform combines your data with external insights to give you clear, actionable recommendations.
                        </p>
                    </Col>
                </Row>
            </Container>


            <Container fluid className="my-3 p-0">
                <Row className="align-items-center">
                    <Col xs={12} md={6} className="text-center content-style">
                        <img src="/images/home/data_security.png" alt="Data Privacy" className="img-fluid trapezoid-img-bottom-right" />
                    </Col>
                    <Col xs={12} md={6} className="text-left content-style">
                        <h2 style={{ color: '#4B88A2', fontWeight: 'bold' }}>
                            <i className="bi bi-shield-lock-fill" style={{ marginRight: '10px', fontSize: '1.5rem', verticalAlign: 'middle' }}></i>
                            Your Data, Your Privacy. Guaranteed.
                        </h2>
                        <p style={{ fontSize: '16px', color: '#252627' }}>
                            We value your privacy. Your data is always secure, private, and never shared with any third parties.
                            Only you have access, and our AI insights are provided solely for your business growth.
                        </p>
                        <p style={{ fontSize: '16px', color: '#252627' }}>
                            This is true the other way around too. Every business is unique, and we do not use anyone else's private data to provide insights for you.
                        </p>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="my-3 pb-1 stats-box-style">
                <Row className="justify-content-center text-center">
                    <Col xs={12} className="content-style">
                        <h2>Your Business, Empowered</h2>
                        <p>
                            Achieve significant improvements across key business areas with actionable insights and predictive analytics tailored to your goals.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4} className="content-style">
                        <div className="number-style">40%</div>
                        <div className="description-style">Increase in operational efficiency</div>
                    </Col>
                    <Col xs={12} md={4} className="content-style">
                        <div className="number-style">50+</div>
                        <div className="description-style">External data points integrated for holistic analysis</div>
                    </Col>
                    <Col xs={12} md={4} className="content-style">
                        <div className="number-style">24/7</div>
                        <div className="description-style">AI-driven support for smarter decisions anytime</div>
                    </Col>
                </Row>
                <Row className="justify-content-start">
                    <Col xs={12} md={6} className="note-style">
                        <p>
                            *Results based on feedback and observations from our initial test customers.
                        </p>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="my-5">
                <Row>
                    <Col xs={12} sm={5} className="content-style">
                        <img src="/images/home/features.jpg"
                            alt="Features"
                            className="img-fluid"
                            style={{
                                width: '100%',
                                height: '400px',
                                objectFit: 'cover',
                                objectPosition: 'bottom'
                            }}
                        />
                    </Col>
                    <Col xs={12} sm={7} className="content-style d-flex flex-column justify-content-center">
                        <div className="d-flex align-items-center my-3">
                            <i className="bi bi-graph-up-arrow" style={{ fontSize: '2rem', marginRight: '20px', verticalAlign: 'middle' }}></i>
                            <div>
                                <h4>Predictive Analytics</h4>
                                <p>Use your data, combined with open data, to predict trends and outcomes like impact from weather, world events, seasonality, market trends, consumer behaviors and more.</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center my-3">
                            <i className="bi bi-calendar-event" style={{ fontSize: '2rem', marginRight: '20px', verticalAlign: 'middle' }}></i>
                            <div>
                                <h4>Event & Decision Analysis</h4>
                                <p>Log events and track decisions to measure their impact and preserve historical data for insights.</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center my-3">
                            <i className="bi bi-chat-dots" style={{ fontSize: '2rem', marginRight: '20px', verticalAlign: 'middle' }}></i>
                            <div>
                                <h4>AI-Powered Insights</h4>
                                <p>Interact with AI for real-time recommendations and proactive suggestions to optimize your strategies.</p>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>

            <Container fluid className="my-5">
                <Row className="align-items-center justify-content-center text-center">
                    <Col xs={12} sm={7} className="content-style bordered-title">
                        <h2>Focus on What You Love</h2>
                        <p>
                            Focus on what you love — creating amazing products, offering exceptional service and building relationships. Let our AI handle the data and analytics, freeing you up to grow your business.
                        </p>
                    </Col>
                </Row>
            </Container>


            <Container fluid className="my-5">
                <Row>
                    <Col xs={12} md={6} className="text-left content-style">
                        <h3 className="mb-4">Testimonials</h3>
                        <h5 className="mb-4">What are our users saying?</h5>
                        <blockquote className="blockquote">
                            <p className="mb-4">
                                "ARAMA has been a real game-changer for my restaurant. The AI-driven insights help me predict customer flow, optimize my inventory, and reduce waste. It saves us time and money every single day."
                            </p>
                            <footer className="blockquote-footer text-right">Carina Becker, <cite title="Source Title">Restaurant Owner, Germany</cite></footer>
                        </blockquote>
                        <blockquote className="blockquote pt-3">
                            <p className="mb-4">
                                "Our fabrication unit has seen incredible improvements in efficiency. Thanks to ARAMA, we can predict material requirements better and cut down on excess inventory. The system is easy to use, and the support team is always there when we need them."
                            </p>
                            <footer className="blockquote-footer text-right">Ravi Adhikari, <cite title="Source Title">Fabrication Business Owner, India</cite></footer>
                        </blockquote>
                        <blockquote className="blockquote pt-3">
                            <p className="mb-4">
                                "As an industrial robotics company, optimizing workflows and managing parts inventory is crucial. ARAMA's predictive analytics have given us better visibility and control, reducing downtime and ensuring smooth operations. It’s powerful, yet intuitive."
                            </p>
                            <footer className="blockquote-footer text-right">Arnaud Martin, <cite title="Source Title">Industrial Robotics Specialist, France</cite></footer>
                        </blockquote>
                    </Col>
                    <Col xs={12} md={6} className="text-center content-style">
                        <img src="/images/home/testimonials.jpg" alt="Testimonials" className="img-fluid" />
                    </Col>
                </Row>
            </Container>

            <Container fluid className="my-5">
                <Row>
                    <Col xs={12} md={4} className="text-left">
                        <h2>Got questions? We’ve got answers.</h2>
                        <p>
                            If you have any more questions, please&nbsp;
                            <Link to="/contact">contact us</Link>.
                            We are always happy to hear from our customers!
                        </p>
                        <div style={{ textAlign: 'left', width: 'fit-content' }}>
                            <Button variant="primary"
                                className="custom-button"
                                onClick={handleOpenModal}
                                style={{
                                    marginTop: '20px',
                                    backgroundColor: '#4B88A2',
                                    padding: '12px 24px', // Larger padding
                                    fontSize: '18px', // Larger font size
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
                                }}>
                                <i className="bi bi-rocket-takeoff" style={{ marginRight: '8px' }}></i>
                                Get Started Now!
                            </Button>
                        </div>

                    </Col>
                    <Col xs={12} md={8}>
                        {/* FAQ section with the number of questions to show */}
                        <FAQ numToShow={5} />
                    </Col>
                </Row>
            </Container>
            <Footer />
            {/* Modal to display the form */}
            <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Get Started with ARAMA</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactUsForm /> {/* Include the form inside the modal */}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Home;
